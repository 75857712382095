<template>
  <div class="dashboard">
    <div class="dashboard__sidebar">
      <sidebar></sidebar>
    </div>
    <div class="dashboard__main">
      <div class="dashboard__topbar">
        <!-- <div class="d-flex flex-grow-1 align-center">
          <h4 class="role">{{ $t("role_as") }}:</h4>
          <tab />
        </div> -->
        <button-language class="d-none d-md-flex ml-5 center" />
        <notification />
      </div>
      <div class="dashboard__body dashboard__body--dense">
        <router-view />
        <Footer></Footer>
      </div>
      <!-- <div class="dashboard__footer">
      </div> -->
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/v2/sidebar/index.vue";
import notification from "@/components/v2/notification/index.vue";
import Footer from "@/components/v2/footer/index.vue";
// import Tab from "@/components/v2/switch_account/tab.vue";
import ButtonLanguage from "@/components/v2/button/button_language.vue";

export default {
  components: {
    Sidebar,
    notification,
    Footer,
    // Tab,
    ButtonLanguage,
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/color.scss";
.dashboard {
  background: $gray_1;
  display: flex;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  position: relative;
  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $gray_1;
    min-width: 200px;
    @media screen and (max-width: 960px) {
      margin-top: 60px;
    }
  }
  &__sidebar {
    background: white;
    @media screen and (max-width: 960px) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      z-index: 9;
    }
  }
  &__topbar {
    background: white;
    padding: 0 36px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    h4 {
      color: $main_2;
      margin-right: 8px;
    }
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  &__body {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // padding: 24px 36px;
    &--dense {
      padding: 0;
    }
    @media screen and (max-width: 960px) {
      overflow: inherit;
      padding: 24px 0;
      &--dense {
        padding: 0;
      }
    }
    @media screen and (max-width: 400px) {
      min-height: 745px;
    }
    @media screen and (min-width: 401px) and (max-width: 550px) {
      min-height: 810px;
    }
    @media screen and (min-width: 551px) and (max-width: 700px) {
      min-height: 860px;
    }
    @media screen and (min-width: 701px) and (max-width: 780px) {
      min-height: 920px;
    }
    @media screen and (min-width: 781px) and (max-width: 825px) {
      min-height: 1073px;
    }
    @media screen and (min-width: 826px) and (max-width: 925px) {
      min-height: 1265px;
    }
    &::-webkit-scrollbar {
      width: 0px;
      background: transparent; /* make scrollbar transparent */
    }
  }
  // &__footer {
  // }
}
.center{
  align-items: center;
  margin-right: 10px;
}
</style>